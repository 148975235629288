import FAQ from './FAQ'

import CommonLayout from '../../UI/CommonLayout'

const FAQPage = () => {
  return (
    <CommonLayout sidebarTitle={'운영 관리'} sidebarSubTitle={'일반 관리'}>
      <FAQ />
    </CommonLayout>
  )
}

export default FAQPage
