import { OverAllMain, OverAllSub, OverAllTable } from '../../../common/Overall/Overall.styled'

import SideBar from '../../../components/Left/SideBar'
import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/Header/SubHeader'
import Round from './Round'
import RoundAucListEdit from './RoundAucListEdit'
import { aucProListEditAtom } from '../../../store/Layout/Layout'
import { useAtom } from 'jotai'
import { useState } from 'react'
import RoundAucListPackEdit from './RoundAucListPackEdit'

const RoundPage = () => {
  const [expanded, setExpanded] = useState('경매 관리')
  const [depth2Color, setDepth2Color] = useState('경매 회차 관리')
  const [type, setType] = useAtom(aucProListEditAtom)


  return (
    <>
      <Header />
      <OverAllMain>
        <SideBar expanded={expanded} setExpanded={setExpanded} depth2Color={depth2Color} />
        <OverAllSub>
          <SubHeader />
          <OverAllTable>{type === '기본' ? <Round /> : type === '수정' ? <RoundAucListEdit /> : null}</OverAllTable>
          {/* 경매 목록 수정(패키지) 여기에다가 해야함 */}
        </OverAllSub>
      </OverAllMain>
    </>
  )
}

export default RoundPage
