import styled from 'styled-components'

// 클레임 등록할 제품 찾기 목록 버튼
export const StyledBtnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-top: 60px;
`
