import React, { useEffect, useState } from 'react'
import { DoubleWrap } from '../../../modal/External/ExternalFilter'
import { useAtomValue } from 'jotai'
import { filterisReset } from '../../../store/Layout/Layout'
import { useSearchParams } from 'react-router-dom'

// 쉼표 또는 새 줄 문자를 찾는 정규식
const regex = /,|\n/

const ProductNumber = ({ stateValue, setState, valueName, title, height = '100px' }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [value, setValue] = useState('')
	const isReset = useAtomValue(filterisReset)
	const prevValue = searchParams.get('productNumberList')

	const onChange = (v) => {
		setValue(v)

		const splitValues = v
			? regex.test(v)
				? v
						?.split(/,|\n/)
						?.map((item) => item.trim())
						?.filter((item) => item !== '')
				: v
			: []

		setState((prevState) => ({ ...prevState, [valueName]: splitValues }))
	}

	useEffect(() => {
		if (isReset) {
			setValue('')
		}
	}, [isReset])

	useEffect(() => {
		if (prevValue) onChange(prevValue)
	}, [prevValue])

	return (
		<DoubleWrap>
			<h6>{title || '제품 번호'}</h6>
			<textarea
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder="복수 조회 진행&#13;&#10;제품 번호 ',' 혹은 enter로&#13;&#10;구분하여 작성해주세요."
				style={{ height }}
			/>
		</DoubleWrap>
	)
}

export default ProductNumber
