// 상시 판매 관리 > 주문 확인 상세

import { getNormalTableRows } from '../../utils/table'

var checkboxSelection = function (params) {
	return params.columnApi.getRowGroupColumns().length === 0
}

var headerCheckboxSelection = function (params) {
	return params.columnApi.getRowGroupColumns().length === 0
}

export const saleProductOrderDetailsCols = getNormalTableRows([
	{
		field: '',
		maxWidth: 50,
		checkboxSelection: checkboxSelection,
		headerCheckboxSelection: headerCheckboxSelection,
	},
	{ field: '순번', minWidth: 100 },
	{ field: '상시판매 번호', minWidth: 100 },
	{ field: '상시판매 주문일자', minWidth: 100 },
	{ field: '패키지명', minWidth: 100 }, // 누락 추가
	{ field: '패키지번호 ', minWidth: 100 }, // 누락 추가
	{ field: '고객사명', minWidth: 100 },
	{ field: '고객코드', minWidth: 100 },
	{
		field: '제품번호',
		minWidth: 100,
		cellRendererParams: (params) => params.data[params.column.colId],
		valueGetter: (v) => v.data[v.column.colId]?.value || '',
	},
	{ field: '프로넘(ProNo)', minWidth: 100 },
	{ field: '창고', minWidth: 100 },
	{ field: '상시판매 상태', minWidth: 100 },
	{ field: '상시판매가', minWidth: 100 },
	{ field: '승인상태', minWidth: 100 },
	{ field: '목적지 코드', minWidth: 100 },
	{ field: '목적지 명', minWidth: 100 },
	{ field: '목적지 주소', minWidth: 100 },
	{ field: '목적지 연락처(사무실)', minWidth: 100 },
	{ field: '목적지담당자 연락처 (휴대폰)', minWidth: 100 },
	{ field: '하차지명', minWidth: 100 },
	{ field: '변경요청 목적지명', minWidth: 100 },
	{ field: '변경요청 목적지 주소', minWidth: 100 },
	{ field: '변경요청 목적지 연락처', minWidth: 100 },
	{ field: '변경요청 목적지 담당자 연락처 (휴대폰)', minWidth: 100 },
	{ field: '변경요청 하차지명', minWidth: 100 }, // 누락 추가
	{ field: '판매구분', minWidth: 100 },
	{ field: '판매유형', minWidth: 100 },
	{ field: '판매가유형', minWidth: 100 },
	{ field: '제품군', minWidth: 100 },
	{ field: '제품등급', minWidth: 100 },
	{ field: '정척여부', minWidth: 100 },
	{ field: '유찰횟수', minWidth: 100 },
	{ field: '제품 단가(원/톤)', minWidth: 100 },
	{ field: '총단가(원/톤)', minWidth: 100 },
	{ field: '제품공급가(원/톤)', minWidth: 100 },
	{ field: '제품부가세(원/톤)', minWidth: 100 },
	{ field: '제품금액 (VAT포함)', minWidth: 100 },
	{ field: '기본 운임단가(원/톤)', minWidth: 100 },
	{ field: '할증 운임단가(원/톤)', minWidth: 100 },
	{ field: '매출할증율', minWidth: 100 }, // 매출할증율?
	{ field: '운임 총단가', minWidth: 100 },
	{ field: '운반비 공급가(원/톤)', minWidth: 100 },
	{ field: '운반비 부가세(원/톤)', minWidth: 100 },
	{ field: '운반비 금액(VAT포함)', minWidth: 100 },
	{ field: '총공급가(원/톤)', minWidth: 100 },
	{ field: '총부가세(원/톤)', minWidth: 100 },
	{ field: '합계금액(원/톤)', minWidth: 100 },
	{ field: '두께', minWidth: 100 },
	{ field: '폭', minWidth: 100 },
	{ field: '길이', minWidth: 100 },
	{ field: '중량', minWidth: 100 },
	{ field: '규격약호', minWidth: 100 },
	{ field: 'TS', minWidth: 100 },
	{ field: 'YP', minWidth: 100 },
	{ field: 'C%', minWidth: 100 },
	{ field: 'EL', minWidth: 100 },
	{ field: 'SI', minWidth: 100 },
	{ field: 'MN', minWidth: 100 },
	{ field: 'P', minWidth: 100 },
	{ field: 'S', minWidth: 100 },
	{ field: '여재원인', minWidth: 100 },
	{ field: '여제원인명', minWidth: 100 },
	{ field: '용도코드', minWidth: 100 },
	{ field: '용도명', minWidth: 100 },
	{ field: '메모', minWidth: 100 },
	{ field: '주문상태', minWidth: 100 },
	{ field: '확정전송 일자', minWidth: 100 },
	{ field: '주문번호', minWidth: 100 },
	// { field: '변경 목적지 번호', minWidth: 80 },
	{ field: '비고', minWidth: 100 },
	{ field: '기본운임단가(원/톤)', minWidth: 100 },
	{ field: '할증운임단가(원/톤)', minWidth: 100 },
	{ field: '매입할증율', minWidth: 100 }, // 누락 추가 (중복)
	{ field: '매입 운임총단가', minWidth: 100 },
	{ field: '매입 운반비공급가(원/톤)', minWidth: 100 },
	{ field: '매입 운반비부가세(원/톤)', minWidth: 100 },
	{ field: '매입 운반비 금액(VAT포함)', minWidth: 100 },
	{ field: '매입운반비', minWidth: 100 },
	{ field: '매출운반비', minWidth: 100 },
	// { field: '재고상태', minWidth: 100 },
	// { field: '카스코 낙찰가', minWidth: 100,  },
	{ field: '최종수정자', minWidth: 100 },
	{ field: '최종수정일시', minWidth: 100 },
])

export const saleProductOrderDetailsTableRowMap = {
	순번: 'index',
	'상시판매 번호': 'auctionNumber',
	'상시판매 주문일자': 'auctionCreateDate',
	고객사명: 'customerName',
	고객코드: 'customerCode',
	제품번호: 'productNumber',
	'프로넘(ProNo)': 'productProNumber',
	창고: 'storageName',
	'상시판매 상태': 'saleStatus',
	상시판매가: 'salePrice',
	승인상태: 'approvalStatus',
	'목적지 코드': 'destinationCode',
	'목적지 명': 'destinationName',
	'목적지 주소': 'customerDestinationAddress',
	'목적지 연락처(사무실)': 'customerDestinationPhone',
	'목적지담당자 연락처 (휴대폰)': 'customerDestinationManagerPhone',
	하차지명: 'customerDestinationName',
	'변경요청 목적지명': 'requestDestinationName',
	'변경요청 목적지 주소': 'requestCustomerDestinationAddress',
	'변경요청 목적지 연락처 (휴대폰)': 'requestCustomerDestinationPhone',
	판매구분: 'saleCategory',
	판매유형: 'saleType',
	판매가유형: 'salePriceType',
	제품군: 'spart',
	제품등급: 'grade',
	정척여부: 'preferThickness',
	유찰횟수: 'failCount',
	'제품 단가(원/톤)': 'productBiddingPrice',
	'총단가(원/톤)': 'totalBiddingPrice',
	'제품공급가(원/톤)': 'orderPrice',
	'제품부가세(원/톤)': 'orderPriceVat',
	'제품금액 (VAT포함)': 'totalOrderPrice',
	'기본 운임단가(원/톤)': 'freightFee',
	'할증 운임단가(원/톤)': 'extraUnitPrice',
	'운임 총단가': 'totalFreightCost',
	'운반비 공급가(원/톤)': 'freightCost',
	'운반비 부가세(원/톤)': 'freightCostVat',
	'운반비 금액(VAT포함)': 'freightCostVat',
	'총공급가(원/톤)': 'totalSupplyPrice',
	'총부가세(원/톤)': 'totalVat',
	'합계금액(원/톤)': 'totalPrice',
	두께: 'thickness',
	폭: 'width',
	길이: 'length',
	중량: 'weight',
	규격약호: 'spec',
	TS: 'ts',
	YP: 'yp',
	'C%': 'c',
	EL: 'el',
	SI: 'si',
	MN: 'mn',
	P: 'p',
	S: 's',
	여재원인: 'causeCode',
	여제원인명: 'causeCodeName',
	용도코드: 'usageCode',
	용도명: 'usageCodeName',
	메모: 'productMemo',
	주문상태: 'orderStatus',
	'확정전송 일자': 'sendDate',
	'변경 목적지 번호': 'requestCustomerDestinationUid',
	주문번호: 'orderUid',
	비고: 'note',
	'기본운임단가(원/톤)': 'inboundFreightFee',
	'할증운임단가(원/톤)': 'inboundExtraUnitPrice',
	'매입 운임총단가': 'inboundFreightCost',
	'매입 운반비공급가(원/톤)': 'inboundFreightTotalCost',
	'매입 운반비부가세(원/톤)': 'inboundFreightCostVat',
	'매입 운반비 금액(VAT포함)': 'totalInboundFreightTotalCost',
	매입운반비: 'inboundFreightAmount',
	매출운반비: 'outboundFreightAmount',
	재고상태: 'stockStatus',
	'카스코 낙찰가': 'confirmPrice',
	최종수정자: 'updateMemberName',
	최종수정일시: 'updateDate',
}
