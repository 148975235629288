import { OverAllMain, OverAllSub, OverAllTable } from '../../../common/Overall/Overall.styled'

import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/Header/SubHeader'
import UserSideBar from '../../../components/Left/UserSideBar'
import Destination from './Destination'
import DestinationPost from './DestinationPost'

import { useState } from 'react'

const DestinationPage = () => {
	const depth2Color = '목적지 관리'
	const [expanded, setExpanded] = useState('마이페이지')
	const [choiceComponent, setChoiceComponent] = useState('리스트')

	const renderChoiceComponent = () => {
		switch (choiceComponent) {
			case '리스트':
				return <Destination setChoiceComponent={setChoiceComponent} />
			case '등록':
				return <DestinationPost setChoiceComponent={setChoiceComponent} />

			default:
				return <Destination setChoiceComponent={setChoiceComponent} />
		}
	}

	return (
		<>
			<Header />
			<OverAllMain>
				<UserSideBar expanded={expanded} setExpanded={setExpanded} depth2Color={depth2Color} />
				<OverAllSub>
					<SubHeader />
					<OverAllTable>{renderChoiceComponent()}</OverAllTable>
				</OverAllSub>
			</OverAllMain>
		</>
	)
}

export default DestinationPage
