import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import {
	BlueBarHeader,
	BlueBlackBtn,
	BlueBtnWrap,
	BlueMainDiv,
	FadeOverlay,
	ModalContainer,
	WhiteCloseBtn,
} from '../../../modal/Common/Common.Styled'
import { MainSelect } from '../../../common/Option/Main'
import useReactQuery from '../../../hooks/useReactQuery'
import { getSpartList, getStorageList } from '../../../api/transPortDrop'
import DateGrid from '../../../components/DateGrid/DateGrid'
import useMutationQuery from '../../../hooks/useMutationQuery'
import { editAdminTransportation } from '../../../service/admin/Standard'
import { queryClient } from '../../../api/query'
import useAlert from '../../../store/Alert/useAlert'

const initValue = {
	destinationName: '',
	destinationCode: '',
	storage: '',
	spart: '',
	effectDate: new Date(),
	effectCost: 0,
}

export default function TransportUpdateModel({ title, data, refetch, modalClose }) {
	const { simpleAlert, simpleConfirm } = useAlert()
	const [editInput, setEditInput] = useState(data || initValue)

	const { data: storageList } = useReactQuery('', 'getStorageList', getStorageList)
	const { data: spartList } = useReactQuery('', 'getSPartList', getSpartList)

	const changeHandler = (name, value) => {
		setEditInput((p) => ({ ...p, [name]: value }))
	}

	// Edit
	const editMutation = useMutationQuery('', editAdminTransportation)
	const propsEdit = () => {
		if (!editInput.storage) {
			simpleAlert('출발지를 선택해주세요.')
			return
		}
		if (!editInput.spart) {
			simpleAlert('제품군을 선택해주세요.')
			return
		}
		if (!editInput.effectDate) {
			simpleAlert('적용일을 설정해주세요.')
			return
		}
		if (editInput.effectCost <= 0) {
			simpleAlert('적용단가를 0 이상으로 설정해주세요.')
			return
		}

		simpleConfirm('수정하시겠습니까?', () => {
			editMutation.mutate(editInput, {
				onSuccess: () => {
					simpleAlert('수정되었습니다.', () => {
						queryClient.invalidateQueries('transportation')
						refetch()
						modalClose()
					})
				},
				onError: (error) => {
					simpleAlert(error?.data?.message || '수정 실패했습니다. 다시 시도해 주세요.')
				},
			})
		})
	}

	return (
		<>
			<FadeOverlay zindex={899} />
			<ModalContainer width={1200} zindex={900}>
				<BlueBarHeader>
					<div>{title}</div>
					<div>
						<WhiteCloseBtn onClick={modalClose} src="/svg/white_btn_close.svg" />
					</div>
				</BlueBarHeader>
				<BlueMainDiv style={{ margin: '0px auto', borderTop: 0, padding: '0px' }}>
					<Table>
						<thead>
							<tr>
								<Th>출발지</Th>
								<Th>목적지 코드</Th>
								<Th>목적지 명</Th>
								<Th>제품구분</Th>
								<Th>단가 적용일</Th>
								<Th>적용 단가</Th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<Td width={5}>
									<MainSelect
										options={storageList}
										defaultValue={storageList.find((item) => item.label === editInput.storage) || storageList[0]}
										name="storage"
										onChange={(e) => changeHandler('storage', e.label)}
									/>
								</Td>
								<Td width={15}>{editInput?.destinationCode}</Td>
								<Td width={20}>{editInput?.destinationName}</Td>
								<Td width={5}>
									<MainSelect
										options={spartList}
										defaultValue={spartList.find((item) => item.label === editInput.spart) || spartList[0]}
										name="spart"
										onChange={(e) => changeHandler('spart', e.label)}
									/>
								</Td>
								<Td width={15}>
									<DateGrid
										width={145}
										startDate={editInput.effectDate}
										setStartDate={(value) => changeHandler('effectDate', moment(value).format('YYYY-MM-DD'))}
									/>
								</Td>
								<Td width={20}>
									<Input
										type="number"
										value={editInput?.effectCost}
										onChange={(e) => changeHandler('effectCost', e.target.value)}
									/>
								</Td>
							</tr>
						</tbody>
					</Table>
				</BlueMainDiv>
				<BlueBtnWrap>
					<BlueBlackBtn onClick={propsEdit}> 저장</BlueBlackBtn>
				</BlueBtnWrap>
			</ModalContainer>
		</>
	)
}

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	overflow-x: scroll;
	margin-top: 20px;
	font-size: 18px;

	thead {
		background-color: #dbe2f0;
		border: 1px solid #ddd;
	}
`

const Th = styled.th`
	border: 1px solid #c8c8c8;
	padding: 8px;
	text-align: center;
	font-weight: 100;
	font-size: 18px;
`

const Td = styled.td`
	border: 1px solid #ddd;
	text-align: center;
	font-weight: 100;
	width: ${(props) => props.width ?? 100}%;
	height: 35px;
	padding: 3px;
	font-size: 18px;
`

const Input = styled.input`
	width: 100%;
	height: 28px;
	border: 1px solid #c8c8c8;
	padding: 4px;
`
