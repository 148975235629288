import { commonStyles } from '../../constants/admin/Shipping'
import { PROD_COL_NAME } from '../../constants/user/constantKey'

export const MainProductFields = {
	제품번호: 'productNumber',
	'프로넘(ProNo)': 'productNoNumber',
	'패키지 번호': 'packageNumber',
	'판매 유형': 'saleType',
	'판매 구분': 'saleCategory',
	규격약호: 'spec',
	두께: 'thickness',
	폭: 'width',
	길이: 'length',
	중량: 'weight',
	제품군: 'spart',
	TS: 'ts',
	YP: 'yp',
	'C%': 'c',
	EL: 'el',
}

export const MainProductFieldsCols = [
	...Object.keys(MainProductFields).map((item) => {
		if (item === '제품번호') {
			return {
				...commonStyles,
				field: PROD_COL_NAME.productNumber,
				minWidth: 200,
				cellRenderer: (item) => (item.data['프로넘(ProNo)'] !== null ? '' : item.data['제품번호']),
			}
		}
		return {
			...commonStyles,
			field: item,
			minWidth: 200,
		}
	}),
]

export const MainPackageProductFields = {
	제품번호: 'productNumber',
	'프로넘(ProNo)': 'productNoNumber',
	패키지번호: 'packageNumber',
	패키지명: 'packageName',
	'판매 유형': 'saleType',
	'판매 구분': 'saleCategory',
	규격약호: 'spec',
	두께: 'thickness',
	폭: 'weight',
	길이: 'length',
	중량: 'width',
	제품군: 'spart',
	TS: 'ts',
	YP: 'yp',
	'C%': 'c',
	EL: 'el',
}

export const MainPackageProductFieldsCols = [
	...Object.keys(MainPackageProductFields).map((item) => {
		{
			if (item === '제품번호') {
				return {
					...commonStyles,
					field: PROD_COL_NAME.productNumber,
					minWidth: 200,
					cellRenderer: (item) => (item.data['프로넘(ProNo)'] !== null ? '' : item.data['제품번호']),
				}
			}
			return {
				...commonStyles,
				field: item,
				minWidth: 200,
			}
		}
	}),
]
