import { OverAllMain, OverAllSub, OverAllTable } from '../../../common/Overall/Overall.styled'

import Header from '../../../components/Header/Header'
import SubHeader from '../../../components/Header/SubHeader'
import UserSideBar from '../../../components/Left/UserSideBar'
import Status from './Status'

import { useState } from 'react'

const StatusPage = () => {
	const depth2Color = '경매 진행 상세 조회'
	const [expanded, setExpanded] = useState('경매')
	return (
		<>
			<Header />
			<OverAllMain>
				<UserSideBar expanded={expanded} setExpanded={setExpanded} depth2Color={depth2Color} />
				<OverAllSub>
					<SubHeader />
					<OverAllTable>
						<Status />
					</OverAllTable>
				</OverAllSub>
			</OverAllMain>
		</>
	)
}

export default StatusPage
