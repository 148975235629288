import { useAtom } from 'jotai'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { BlackBtn, BtnBound, NewBottomBtnWrap, TGreyBtn, WhiteBlackBtn } from '../../../common/Button/Button'
import Excel from '../../../components/TableInner/Excel'
import PageDropdown from '../../../components/TableInner/PageDropdown'
import {
	CustomInput,
	FilterContianer,
	FilterHeader,
	FilterTCTop,
	TCSubContainer,
	TableContianer,
} from '../../../modal/External/ExternalFilter'
import { invenDestination, invenDestinationData, selectedRowsAtom, toggleAtom } from '../../../store/Layout/Layout'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { destiApproveReq, getAuctionDetailDestination, getWinningDetail } from '../../../api/auction/winning'
import { ClaimContent, ClaimRow, ClaimTable, ClaimTitle } from '../../../components/MapTable/MapTable'
import { UserAuctionWinningDetailFields, UserAuctionWinningDetailFieldsCols } from '../../../constants/admin/Auction'
import useReactQuery from '../../../hooks/useReactQuery'
import useTableData from '../../../hooks/useTableData'
import useTableSelection from '../../../hooks/useTableSelection'
import InventoryFind from '../../../modal/Multi/InventoryFind'
import Table from '../../../pages/Table/Table'
import TableV2HiddenSection from '../../../pages/Table/TableV2HiddenSection'
import useAlert from '../../../store/Alert/useAlert'
import PrintDepositRequestButton from '../../UserSales/_components/PrintDepositRequestButton'

const WinningDetail = ({ setAucDetail }) => {
	const navigate = useNavigate()
	const { search } = useLocation()
	const [detailRow, setDetailRow] = useState(null)
	const queryParams = new URLSearchParams(search)
	const { simpleAlert, simpleConfirm, showAlert } = useAlert()
	const [destinationPopUp, setDestinationPopUp] = useAtom(invenDestination)
	const [contentData, setContentData] = useState([])

	const titleData = [
		'경매 번호',
		'고객사 명',
		'고객 코드',
		'창고',
		'총 수량',
		'총 중량',
		'제품 금액 (VAT 포함)',
		'운반비 금액 (VAT 포함)',
		'입금 요청 금액',
	]

	useEffect(() => {
		const paramMapping = {
			auctionNumber: '경매 번호',
			customerName: '고객사명',
			customerCode: '고객 코드',
			storage: '창고',
			quantity: '수량',
			weight: '중량',
			productAmount: '제품 금액 (VAT 포함)',
			transportationFee: '운반비 금액 (VAT 포함)',
			depositRequestAmount: '입금 요청액',
			customerDestinationUid: '고객사 목적지 고유 번호',
			biddingStatus: '낙찰 상태',
		}

		const detailRowData = Object.entries(paramMapping).reduce((acc, [key, value]) => {
			const paramValue = queryParams.get(key)
			if (paramValue) acc[value] = paramValue
			return acc
		}, {})

		if (
			detailRowData['경매 번호'] &&
			detailRowData['창고'] &&
			detailRowData['고객사 목적지 고유 번호'] &&
			detailRowData['낙찰 상태']
		) {
			setDetailRow(detailRowData)
		}
	}, [search])

	useEffect(() => {
		if (detailRow) {
			const newContentData = [
				detailRow['경매 번호'],
				detailRow['고객사명'],
				detailRow['고객 코드'],
				detailRow['창고'],
				detailRow['수량'],
				detailRow['중량'],
				detailRow['제품 금액 (VAT 포함)'] + '원',
				detailRow['운반비 금액 (VAT 포함)'] + '원',
				detailRow['입금 요청액'] + '원',
			]
			// 새로운 contentData 값을 상태로 업데이트
			setContentData(newContentData)
		}
	}, [detailRow])

	const matchingData = {
		'경매 번호': 'auctionNumber',
		창고: 'storage',
		'고객사 목적지 고유 번호': 'customerDestinationUid',
		'낙찰 상태': 'biddingStatus',
		'패키지 번호': 'packageNumber',
	}

	// 상세 GET 및 param
	// function matchDetailRowWithMatchingData(detailRow, matchingData) {
	// 	const matchedData = {}

	// 	Object.keys(matchingData).forEach((key) => {
	// 		const detailKey = matchingData[key]
	// 		if (detailRow.hasOwnProperty(key)) {
	// 			matchedData[detailKey] = detailRow[key]
	// 		}
	// 	})

	// 	return matchedData
	// }
	// 상세 GET 및 param
	// const matchedResult = matchDetailRowWithMatchingData(detailRow, matchingData)

	const paramData = {
		pageNum: 1,
		pageSize: 50,
		auctionNumber: detailRow?.['경매 번호'],
		storage: detailRow?.['창고'],
		customerDestinationUid: detailRow?.['고객사 목적지 고유 번호'],
		biddingStatus: detailRow?.['낙찰 상태'],
	}

	useEffect(() => {
		if (detailRow) {
			setParam(paramData)
		}
	}, [detailRow])

	// Test 후 주석 해제 필
	const [param, setParam] = useState(paramData)

	const handleSelectChange = (selectedOption, name) => {
		// setInput(prevState => ({
		//   ...prevState,
		//   [name]: selectedOption.label,
		// }));
	}
	const [isRotated, setIsRotated] = useState(false)

	// Function to handle image click and toggle rotation
	const handleImageClick = () => {
		setIsRotated((prevIsRotated) => !prevIsRotated)
	}

	// 토글 쓰기
	const [exFilterToggle, setExfilterToggle] = useState(toggleAtom)
	const [toggleMsg, setToggleMsg] = useState('On')
	const toggleBtnClick = () => {
		setExfilterToggle((prev) => !prev)
		if (exFilterToggle === true) {
			setToggleMsg('Off')
		} else {
			setToggleMsg('On')
		}
	}

	const [getRow, setGetRow] = useState('')
	const tableField = useRef(UserAuctionWinningDetailFieldsCols)
	const getCol = tableField.current
	const queryClient = useQueryClient()
	const [checkedArray, setCheckedArray] = useAtom(selectedRowsAtom)
	const [tablePagination, setTablePagination] = useState([])
	const [destinationData, setDestinationData] = useAtom(invenDestinationData)

	const init = {
		updateList: [],
	}
	const [input, setInput] = useState(init)

	const handleTablePageSize = (event) => {
		setParam((prevParam) => ({
			...prevParam,
			pageSize: Number(event.target.value),
			pageNum: 1,
		}))
	}

	const { isLoading, isError, data, isSuccess, refetch } = useReactQuery(param, 'getWinningDetail', getWinningDetail)

	const originData = data?.data?.data
	const [oriData, setOridata] = useState()

	const resData = data?.data?.data?.list
	const resPagination = data?.data?.data?.pagination
	const [winningCreateData, setWinningCreateData] = useState({})

	const newCustomerCode = resData?.map((x) => x?.code)[0]

	const { data: inventoryDestination } = useReactQuery(
		newCustomerCode,
		'getAuctionDetailDestination',
		getAuctionDetailDestination,
	)

	const resDestiData = inventoryDestination?.data?.data

	// useEffect(() => {
	// 	if (destinationData) {
	// 		const filteredDestinationData = destinationData?.filter((item) => item.uid === destinationData?.uid)
	// 	}
	// }, [destinationData])

	useEffect(() => {
		queryClient.invalidateQueries('getWinningDetail')
	}, [])

	// 예외 처리
	// useEffect(() => {
	// 	if (isSuccess && resData === undefined && !detailRow) navigate('/userpage/auctionwinning')
	// }, [isSuccess, resData, detailRow])

	useEffect(() => {
		//타입, 리액트쿼리, 데이터 확인 후 실행
		if (!isSuccess && !resData) return
		if (Array.isArray(resData)) {
			setOridata(originData)
			// setGetRow(add_element_field(resData, UserAuctionWinningDetailFields))
			setTablePagination(resPagination)
		}
	}, [isSuccess, resData, data])

	const tableFields = useMemo(() => {
		return UserAuctionWinningDetailFieldsCols(checkedArray)
	}, [checkedArray])

	useEffect(() => {
		const productNumbers = checkedArray?.map((item) => item['주문 고유 번호'])

		const updatedBiddingList = productNumbers?.map((uid) => ({
			uid,
		}))

		setInput((prevInput) => ({
			...prevInput,
			updateList: updatedBiddingList?.map((item) => ({
				...item,
				uid: item.uid, // 유지하고 싶은 다른 속성은 그대로 두고
			})),
		}))

		// setBiddingList(updatedBiddingList)
	}, [checkedArray])

	const [destiObject, setDestiObject] = useState()
	const [tableDesti, setTabelDesti] = useState({})

	const [finalInput, setFinalInput] = useState({
		requestCustomerDestinationUid: null,
	})

	useEffect(() => {
		setDestiObject(destinationData)
	}, [destinationData])

	const matchedDestination = resDestiData?.find((item) => item.uid === destinationData?.uid)

	const uids = checkedArray?.map((item) => item && item['제품 번호']?.value)

	// 목적지 적용 버튼 onClick Handler
	const destiOnClickHandler = () => {
		if (!uids || uids?.length === 0) {
			simpleAlert('적용할 경매를 선택해주세요.')
			return
		}
		simpleAlert('적용 되었습니다.', () => {
			setFinalInput((prevFinalInput) => ({
				...prevFinalInput,
				requestCustomerDestinationUid: destiObject && destiObject.uid,
			}))
		})

		const updatedResData = resData?.map((item) => {
			if (uids.includes(item.productNumber)) {
				item.requestDestinationName = matchedDestination?.destinationName
				item.requestDestinationAddress = matchedDestination?.address
				item.requestDestinationPhone = matchedDestination?.managerPhone
				item.requestDestinationManagerPhone = matchedDestination?.phone
			}
			return item
		})

		setOridata((prevData) => ({
			...prevData,
			list: updatedResData,
		}))
	}

	useEffect(() => {
		const updatedProductList = checkedArray?.map((item) => ({
			uid: item['주문 고유 번호'],
			requestCustomerDestinationUid: finalInput?.requestCustomerDestinationUid,
			// 여기에 다른 필요한 속성을 추가할 수 있습니다.
		}))

		// winningCreateData를 업데이트하여 productList를 갱신
		setWinningCreateData((prevData) => ({
			...prevData,
			updateList: updatedProductList,
		}))
	}, [checkedArray, finalInput])

	const onPageChange = (value) => {
		setParam((prevParam) => ({
			...prevParam,
			pageNum: Number(value),
		}))
	}

	// 목적지 승인 요청 POST
	const { mutate: destiApproveMutation } = useMutation(destiApproveReq, {
		onSuccess() {
			showAlert({
				title: '목적지 승인요청이 완료되었습니다.',
				content: '',
				func: () => {
					refetch()
					queryClient.invalidateQueries('destiApprove')
					setWinningCreateData({})
				},
			})
		},
		onError: (error) => {
			simpleAlert(error?.data?.message || '오류가 발생했습니다. 다시 시도해주세요.')
		},
	})
	const destiApproveOnClickHandler = () => {
		destiApproveMutation(winningCreateData)
	}

	const { selectedData, selectedWeightStr, selectedWeight, selectedCountStr } = useTableSelection({
		weightKey: '중량',
	})

	const { tableRowData, paginationData, totalWeightStr, totalCountStr, totalCount } = useTableData({
		tableField: UserAuctionWinningDetailFields,
		serverData: oriData,
		wish: { display: true, key: ['productNumber', 'packageNumber'] },
		best: { display: true },
	})

	const globalProductResetOnClick = () => {
		setParam(paramData)
	}
	// import
	const globalProductSearchOnClick = (userSearchParam) => {
		setParam((prevParam) => {
			if (isEqual(prevParam, { ...prevParam, ...userSearchParam })) {
				refetch()
				return prevParam
			}
			return {
				...prevParam,
				...userSearchParam,
			}
		})
	}

	const totalFreightCost = oriData?.list
		?.map((item) => Number(item?.totalFreightCost))
		?.reduce((acc, cur) => Number(acc) + Number(cur), 0)

	return (
		<FilterContianer>
			<div>
				<FilterHeader>
					<h1>낙찰 확인 상세</h1>
				</FilterHeader>
				<FilterTCTop>
					<h6>경매 번호</h6>
					<p>{contentData[0]}</p>
				</FilterTCTop>

				<ClaimTable style={{ marginBottom: '30px' }}>
					{[0, 1, 2].map((index) => (
						<ClaimRow key={index}>
							{titleData.slice(index * 3, index * 3 + 3).map((title, idx) => (
								<Fragment key={idx}>
									<ClaimTitle>{title}</ClaimTitle>
									<ClaimContent>
										{title === '총 수량'
											? totalCountStr + ' 개'
											: title === '총 중량'
											? totalWeightStr + ' kg'
											: title === '운반비 금액 (VAT 포함)'
											? totalFreightCost?.toLocaleString() + '원'
											: contentData[index * 3 + idx]}
									</ClaimContent>
								</Fragment>
							))}
						</ClaimRow>
					))}
				</ClaimTable>
			</div>
			{/*{exFilterToggle && (*/}
			{/*	<>*/}
			{/*		<GlobalProductSearch*/}
			{/*			param={param}*/}
			{/*			setParam={setParam}*/}
			{/*			isToggleSeparate={true}*/}
			{/*			renderCustomSearchFields={(props) => <WinningDetailFields {...props} />}*/}
			{/*			globalProductSearchOnClick={globalProductSearchOnClick}*/}
			{/*			globalProductResetOnClick={globalProductResetOnClick}*/}
			{/*		/>*/}
			{/*	</>*/}
			{/*)}*/}
			<TableContianer>
				<TCSubContainer bor>
					<div>
						조회 목록 (선택 <span>{selectedCountStr}</span> / {totalCountStr}개 )
						<TableV2HiddenSection />
					</div>
					<div style={{ display: 'flex', gap: '10px' }}>
						<PageDropdown handleDropdown={handleTablePageSize} />
						<Excel getRow={tableRowData} sheetName="낙찰 확인 상세" />
					</div>
				</TCSubContainer>
				<TCSubContainer>
					<div>
						선택 중량 <span> {selectedWeightStr} </span> (kg) / 총 중량 {totalWeightStr} (kg)
					</div>
					<div style={{ display: 'flex', gap: '10px' }}>
						<P>목적지</P>
						<CustomInput placeholder="h50" width={60} height={32} defaultValue={destinationData?.code} readOnly />
						<CustomInput placeholder="목적지명" width={120} height={32} defaultValue={destinationData?.name} readOnly />
						<WhiteBlackBtn
							onClick={() => {
								setDestinationPopUp(true)
							}}
						>
							찾기
						</WhiteBlackBtn>
						<TGreyBtn style={{ minWidth: '50px' }} onClick={destiOnClickHandler}>
							적용
						</TGreyBtn>
						<BtnBound />
						<WhiteBlackBtn
							onClick={() => {
								destiApproveOnClickHandler()
							}}
						>
							목적지 승인 요청
						</WhiteBlackBtn>
					</div>
				</TCSubContainer>
				<Table
					getCol={tableFields}
					getRow={tableRowData}
					tablePagination={tablePagination}
					onPageChange={onPageChange}
				/>
				<TCSubContainer>
					<div></div>
					<div style={{ display: 'flex', gap: '10px' }}>
						{/* 입금 확인 요청서 */}
						<PrintDepositRequestButton
							auctionNumber={param?.auctionNumber}
							storage={param?.storage}
							customerDestinationUid={param?.customerDestinationUid}
							biddingStatus={param?.biddingStatus}
							packagerNumber={param?.packageNumber}
						/>
						<div></div>
					</div>
				</TCSubContainer>
				<NewBottomBtnWrap>
					<BlackBtn
						width={13}
						height={40}
						onClick={() => {
							navigate('/userpage/auctionwinning')
							window.location.reload()
						}}
					>
						돌아가기
					</BlackBtn>
				</NewBottomBtnWrap>
			</TableContianer>
			{destinationPopUp && (
				<InventoryFind title={'목적지 찾기'} setSwitch={setDestinationPopUp} data={inventoryDestination} />
			)}
		</FilterContianer>
	)
}

export default WinningDetail

const P = styled.p`
	position: relative;
	top: 5px;
`
