/**
 * @constant 상품 카테고리(단일|패키지)
 */
export const PROD_CATEGORY = Object.freeze({
	single: 'SINGLE',
	package: 'PACKAGE',
})

/**
 * @constant 칼럼 명
 */
export const PROD_COL_NAME = Object.freeze({
	packageNumber: '패키지 번호',
	productNumber: '제품 번호',
	productUid: '순번',
	packageUid: '패키지 고유 번호',
	salePrice: '상시 판매가',
	cartUid: '장바구니 번호',
})
