import { useAtom, useAtomValue } from 'jotai'
import { winningDetailAucNumAtom, winningDetailModal } from '../../store/Layout/Layout'
import { Link } from 'react-router-dom'
import { authAtom } from '../../store/Auth/auth'

var checkboxSelection = function (params) {
	// we put checkbox on the name if we are not doing grouping
	return params.columnApi.getRowGroupColumns().length === 0
}

var headerCheckboxSelection = function (params) {
	// we put checkbox on the name if we are not doing grouping
	return params.columnApi.getRowGroupColumns().length === 0
}

export const commonStyles = {
	headerClass: 'custom-header-style',
	flex: 1,
	cellStyle: { borderRight: '1px solid #c8c8c8', textAlign: 'center' },
}

const LinkRenderer = (props) => {
	const auth = useAtomValue(authAtom)
	const url = auth.role === '카스코철강' ? '/auction/winning/detail' : '/userpage/auctionwinning/detail'
	const { data } = props
	const [aucDetailModal, setAucDetailModal] = useAtom(winningDetailModal)

	const queryString = new URLSearchParams({
		auctionNumber: data['경매 번호'],
		storage: data['창고'],
		customerDestinationUid: data['고객사 목적지 고유 번호'],
		biddingStatus: data['낙찰 상태'],
		quantity: data['제품 수량'],
		weight: data['중량 합계'],
		productAmount: data['제품 금액 (VAT 포함)'],
		transportationFee: data['운반비 (VAT 포함)'],
		depositRequestAmount: data['입금 요청액'],
		customerName: data['고객사명'],
		customerCode: data['고객 코드'],
	}).toString()

	return (
		<>
			{aucDetailModal ? (
				<>{props.value || ''}</>
			) : (
				<Link
					to={`${url}?${queryString}`}
					onClick={() => setAucDetailModal(true)}
					style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bolder' }}
					rel="noreferrer"
				>
					{props.value || ''}
				</Link>
			)}
		</>
	)
}

/* ====================================
    경매 관리 - 경매 낙찰 관리 (winning
======================================= */

export const AuctionWinningFields = {
	순번: 'index', // 추가
	경매일자: 'auctionStartDate', // 추가
	'Pro.No 번호': 'productNoNumber',
	'경매 번호': 'auctionNumber',
	패키지명: 'packageName',
	'패키지 번호': 'packageNumber',
	'고객 코드': 'code',
	고객사명: 'customerName',
	창고: 'storage',
	'고객사 목적지 고유 번호': 'customerDestinationUid',
	제품군: 'spart',
	'판매 구분': 'saleCategory',
	'판매 유형': 'saleType',
	'판매가 유형': 'salePriceType',
	'제품 수량': 'productCount',
	'중량 합계': 'weight',
	'제품 금액 (VAT 포함)': 'orderAmount',
	'운반비 금액 (VAT 포함)': 'freightAmount',
	'목적지 고유 번호': 'destinationUid',
	'목적지 명': 'destinationName',
	'목적지 주소': 'destinationAddress',
	'목적지 연락처(사무실)': 'destinationPhone',
	'목적지 담당자 연락처(휴대폰)': 'destinationManagerPhone',
	'하차지 명': 'customerDestinationName',
	'승인 상태': 'requestStatus',
	'낙찰 상태': 'biddingStatus',
	'주문 상태': 'orderStatus',
	'입금 요청액': 'amount',
	'카스코 낙찰가': 'confirmPrice',
	'매입 운반비': 'inboundFreightAmount',
	'매입 운반비 적용 할증율': 'inboundFreightExtraRete',
	'매출 운반비': 'outboundFreightAmount',
	'매출 운반비 적용 할증율': 'outboundFreightExtraRete',
	'확정전송 일자': 'sendDate', // 추가
	주문번호: 'orderNumber', // 추가
	비고: 'note', // 추가
	최종수정자: 'updateMemberName',
	최종수정일시: 'updateDate',
}

export const AuctionWinningFieldsCols = [
	{
		...commonStyles,
		field: '',
		maxWidth: 50,
		checkboxSelection,
		headerCheckboxSelection,
		lockVisible: true,
		lockPinned: true,
	},
	// 순번 추가
	{
		headerName: '경매 번호',
		field: '경매 번호',
		headerClass: 'custom-header-style',
		cellStyle: { borderRight: '1px solid #c8c8c8', textAlign: 'center' },
		width: 150,
		cellRenderer: LinkRenderer,
	},
	{ ...commonStyles, field: '패키지명', minWidth: 120 },
	{ ...commonStyles, field: '패키지 번호', minWidth: 120 },
	{ ...commonStyles, field: '고객사명', minWidth: 150 },
	{ ...commonStyles, field: '고객 코드', minWidth: 100 },
	{ ...commonStyles, field: '창고', minWidth: 100 },
	{ ...commonStyles, field: '낙찰 상태', minWidth: 120 },
	{ ...commonStyles, field: '승인 상태', minWidth: 120 },
	{ ...commonStyles, field: '판매 구분', minWidth: 120 },
	{ ...commonStyles, field: '판매 유형', minWidth: 120 },
	{ ...commonStyles, field: '판매가 유형', minWidth: 120 },
	{ ...commonStyles, field: '제품군', minWidth: 100 },
	{ ...commonStyles, field: '제품 수량', minWidth: 100 },
	{ ...commonStyles, field: '중량 합계', minWidth: 100 },
	{ ...commonStyles, field: '제품 금액 (VAT 포함)', minWidth: 120 },
	{ ...commonStyles, field: '운반비 금액 (VAT 포함)', minWidth: 120 },
	{ ...commonStyles, field: '입금 요청액', minWidth: 120 },
	{ ...commonStyles, field: '목적지 명', minWidth: 120 },
	{ ...commonStyles, field: '목적지 주소', minWidth: 150 },
	{ ...commonStyles, field: '목적지 연락처(사무실)', minWidth: 150 },
	{ ...commonStyles, field: '목적지 담당자 연락처(휴대폰)', minWidth: 150 },
	{ ...commonStyles, field: '하차지 명', minWidth: 150 },
	{ ...commonStyles, field: '주문 상태', minWidth: 120 },
	{ ...commonStyles, field: '확정전송 일자', minWidth: 120 }, // 누락 추가
	{ ...commonStyles, field: '주문번호', minWidth: 120 }, // 누락 추가
	{ ...commonStyles, field: '비고', minWidth: 120 }, // 누락 추가
	{ ...commonStyles, field: '매입 운반비', minWidth: 120 },
	{ ...commonStyles, field: '매출 운반비', minWidth: 120 },
	{ ...commonStyles, field: '카스코 낙찰가', minWidth: 120 },
	{ ...commonStyles, field: '최종수정자', minWidth: 120 },
	{ ...commonStyles, field: '최종수정일시', minWidth: 120 },
]

export const UserAuctionWinningFieldsCols = [
	{
		...commonStyles,
		field: '',
		maxWidth: 50,
		checkboxSelection,
		headerCheckboxSelection,
		lockVisible: true,
		lockPinned: true,
	},
	// 순번 추가
	{ ...commonStyles, field: '순번', minWidth: 50 },
	{ ...commonStyles, field: '경매일자', minWidth: 120 },

	{
		headerName: '경매 번호',
		field: '경매 번호',
		headerClass: 'custom-header-style',
		cellStyle: { borderRight: '1px solid #c8c8c8', textAlign: 'center' },
		width: 150,
		cellRenderer: LinkRenderer,
	},
	{ ...commonStyles, field: '패키지명', minWidth: 120 },
	{ ...commonStyles, field: '패키지 번호', minWidth: 120 },
	{ ...commonStyles, field: '고객사명', minWidth: 150 },
	{ ...commonStyles, field: '고객 코드', minWidth: 100 },
	{ ...commonStyles, field: '창고', minWidth: 100 },
	{ ...commonStyles, field: '낙찰 상태', minWidth: 120 },
	{ ...commonStyles, field: '승인 상태', minWidth: 120 },
	{ ...commonStyles, field: '판매 유형', minWidth: 120 },
	{ ...commonStyles, field: '판매가 유형', minWidth: 120 },
	{ ...commonStyles, field: '제품군', minWidth: 100 },
	{ ...commonStyles, field: '제품 수량', minWidth: 100 },
	{ ...commonStyles, field: '중량 합계', minWidth: 100 },
	{ ...commonStyles, field: '제품 금액 (VAT 포함)', minWidth: 120 },
	{ ...commonStyles, field: '운반비 금액 (VAT 포함)', minWidth: 120 },
	{ ...commonStyles, field: '입금 요청액', minWidth: 120 },
	{ ...commonStyles, field: '목적지 명', minWidth: 120 },
	{ ...commonStyles, field: '목적지 주소', minWidth: 150 },
	{ ...commonStyles, field: '목적지 연락처(사무실)', minWidth: 150 },
	{ ...commonStyles, field: '목적지 담당자 연락처(휴대폰)', minWidth: 150 },
	{ ...commonStyles, field: '하차지 명', minWidth: 150 },
	// 메모
	{ ...commonStyles, field: '최종수정자', minWidth: 120 },
	{ ...commonStyles, field: '최종수정일시', minWidth: 120 },
]
