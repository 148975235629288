import ClaimCellRender from '../../pages/Operate/Common/Claim/components/ClaimCellRender'
import { checkboxSelection, headerCheckboxSelection } from '../../pages/Table/util'
import { commonStyles } from './Auction'
/* ==============================
    운영 관리 - 클레임 관리
============================== */

// 클레임 관리 목록 헤더
export const ClaimListFieldCols = [
	{
		field: '',
		checkboxSelection,
		headerCheckboxSelection,
		minWidth: 50,
		...commonStyles,
		lockVisible: true,
		lockPinned: true,
	},
	{ field: '순번', ...commonStyles, minWidth: 100 },
	{ field: '수정', minWidth: 90, cellRenderer: ClaimCellRender, ...commonStyles },
	{ field: '경매일자', minWidth: 200, ...commonStyles },
	{ field: '경매번호', ...commonStyles, minWidth: 150 },
	{ field: '입고일자', minWidth: 200, ...commonStyles },
	{ field: '매입처', minWidth: 100, ...commonStyles },
	{ field: '제조사', minWidth: 100, ...commonStyles },
	{ field: '창고', minWidth: 100, ...commonStyles },
	{ field: '판매구분', minWidth: 100, ...commonStyles },
	{ field: '판매유형', minWidth: 100, ...commonStyles },
	{ field: '판매가유형', minWidth: 100, ...commonStyles },
	{ field: '주문상태', ...commonStyles, minWidth: 100 },
	{ field: '확정전송일자자', minWidth: 200, ...commonStyles },
	{ field: '출하 상태', ...commonStyles, minWidth: 100 },
	// 출고 일자
	{ field: '고객사명', ...commonStyles, minWidth: 100 },
	{ field: '고객코드', ...commonStyles, minWidth: 100 },
	{ field: '제품번호', ...commonStyles, minWidth: 150 },
	{ field: '제품군', ...commonStyles, minWidth: 100 },
	// 제품 등급
	{ field: '정척여부', ...commonStyles, minWidth: 100 },
	{ field: '유찰횟수', ...commonStyles, minWidth: 100 },
	// 두께
	// 폭
	// 길이
	// 중량
	{ field: '규격약호', ...commonStyles, minWidth: 150 },
	// ts ...
	{ field: '여재원인', ...commonStyles, minWidth: 100 },
	{ field: '여재원인명', ...commonStyles, minWidth: 100 },
	{ field: '용도코드', ...commonStyles, minWidth: 100 },
	{ field: '용도명', ...commonStyles, minWidth: 200 },
	{ field: '메모', ...commonStyles, minWidth: 100 },
	{ field: '재고상태', ...commonStyles, minWidth: 100 },
	{ field: '등록일', ...commonStyles, minWidth: 200 },
	{ field: '고객사반품일', ...commonStyles, minWidth: 200 },
	{ field: '카스코반품일', ...commonStyles, minWidth: 200 },
	{ field: '종료일', ...commonStyles, minWidth: 200 },
	{ field: '최종수정자', ...commonStyles, minWidth: 200 },
	{ field: '최종수정일', ...commonStyles, minWidth: 200 },
]

// 클레임 관리 목록 키값 맵핑
export const ClaimListFields = {
	순번: 'id',
	고유값: 'uid',
	경매일자: 'auctionStartDate',
	입고일자: 'receiptDate',
	매입처: 'supplier',
	제조사: 'maker',
	창고: 'storageName',
	판매구분: 'saleCategory',
	판매유형: 'saleType',
	판매가유형: 'salePriceType',
	주문상태: 'orderStatus',
	확정전송일자자: 'sendDate',
	경매번호: 'auctionNumber',
	출하상태: 'shipmentStatus',
	고객사명: 'customerName',
	고객코드: 'customerCode',
	제품번호: 'productNumber',
	제품군: 'spart',
	정척여부: 'preferThickness',
	유찰횟수: 'failCount',
	규격약호: 'spec',
	여재원인: 'causeCode',
	여재원인명: 'causeCodeName',
	용도코드: 'usageCode',
	용도명: 'usageCodeName',
	메모: 'memo',
	재고상태: 'stockStatus',
	등록일: 'createDate',
	고객사반품일: 'hsReturnDate',
	카스코반품일: 'kaskoReturnDate',
	종료일: 'endDate',
	최종수정자: 'updateMember',
	최종수정일: 'updateDate',
}

// 클레임 등록할 제품 찾기 목록 헤더
export const ClaimProductListFieldCols = [
	{
		...commonStyles,
		field: '',
		checkboxSelection,
		headerCheckboxSelection,
		maxWidth: 50,
		lockVisible: true,
		lockPinned: true,
	},
	{ ...commonStyles, field: '순번', maxWidth: 100 },
	{ ...commonStyles, field: '제품 번호' },
	{ ...commonStyles, field: '창고' },
	{ ...commonStyles, field: 'Pro. No 번호' },
	{ ...commonStyles, field: '경매 번호' },
	{ ...commonStyles, field: '등록일자' },
	{ ...commonStyles, field: '입고 상태' },
	{ ...commonStyles, field: '주문 상태' },
	{ ...commonStyles, field: '입고일' },
	{ ...commonStyles, field: '매입처' },
	{ ...commonStyles, field: '제조사' },
	{ ...commonStyles, field: '제품군' },
	{ ...commonStyles, field: '정척 여부' },
	{ ...commonStyles, field: '유찰 횟수' },
	{ ...commonStyles, field: '매입가' },
	{ ...commonStyles, field: '경매 등록 상태' },
	{ ...commonStyles, field: '경매 시작 단가' },
	{ ...commonStyles, field: '판매 구분' },
	{ ...commonStyles, field: '판매 제외 사유' },
	{ ...commonStyles, field: '판매 유형' },
	{ ...commonStyles, field: '판매가 유형' },
	{ ...commonStyles, field: '제품등급' },
	{ ...commonStyles, field: '두께' },
	{ ...commonStyles, field: '폭' },
	{ ...commonStyles, field: '길이' },
	{ ...commonStyles, field: '중량' },
	{ ...commonStyles, field: 'ts' },
	{ ...commonStyles, field: 'yp' },
	{ ...commonStyles, field: 'c%' },
	{ ...commonStyles, field: 'el' },
	{ ...commonStyles, field: 'si' },
	{ ...commonStyles, field: 'mn' },
	{ ...commonStyles, field: 'p' },
	{ ...commonStyles, field: 's' },
	{ ...commonStyles, field: '여재 원인명' },
	{ ...commonStyles, field: '여재 원인' },
	{ ...commonStyles, field: '용도 코드' },
	{ ...commonStyles, field: '용도명' },
	{ ...commonStyles, field: '재고 상태' },
	{ ...commonStyles, field: '확정전송일자' },
	{ ...commonStyles, field: '클레임 진행상태' },
	{ ...commonStyles, field: '수정일' },
	{ ...commonStyles, field: '최종수정자' },
	{ ...commonStyles, field: '메모' }, // memo
	{ ...commonStyles, field: '제품 고유 번호' }, // number
	{ ...commonStyles, field: '여재 원인' },
	{ ...commonStyles, field: '현대제철 반품일자' },
	{ ...commonStyles, field: '카스코 반품일자' },
	{ ...commonStyles, field: '규격 약호' },
	{ ...commonStyles, field: '낙찰 상태' },
	{ ...commonStyles, field: '상시판매가' },
	{ ...commonStyles, field: '상시 판매 상태' },
	{ ...commonStyles, field: '제품 낙찰 단가(원/톤)' },
	{ ...commonStyles, field: '낙찰 총 단가(원/톤)' },
	{ ...commonStyles, field: '기본 운임 단가' },
	{ ...commonStyles, field: '할증 운임 단가' },
	{ ...commonStyles, field: '제품 공급가(원/톤)' },
	{ ...commonStyles, field: '제품 부가세' },
	{ ...commonStyles, field: '운반비 공급가' },
	{ ...commonStyles, field: '운반비 부가세' },
	{ ...commonStyles, field: '제품 금액' },
	{ ...commonStyles, field: '운반비금액' },
	{ ...commonStyles, field: '총 공급가(원/톤)' },
	{ ...commonStyles, field: '총 부가세(원/톤)' },
	{ ...commonStyles, field: '합계 금액' },
	{ ...commonStyles, field: '목적지명' },
	{ ...commonStyles, field: '패키지명' },
	{ ...commonStyles, field: '패키지 번호' },
	{ ...commonStyles, field: '비고' },
]

/**
 * @ddescription
 * 없는 값
 * 운임총단가
 * 운반비금액
 * 총공급가
 * 총부가세
 * 합계금액

 * spec
 */

// 클레임 등록할 제품 찾기 목록 키값 맵핑
export const ClaimProductListFields = {
	순번: 'index',
	고유값: 'uid',
	제품등급: 'grade',
	중량: 'weight',
	두께: 'thickness',
	폭: 'width',
	길이: 'length',
	yp: 'yp',
	ts: 'ts',
	'c%': 'c',
	p: 'p',
	s: 's',
	si: 'si',
	el: 'el',
	mn: 'mn',
	'제품 고유 번호': 'uid',
	창고: 'storageName',
	입고일: 'receiptDate',
	제품군: 'spart',
	'재고 상태': 'stockStatus',
	매입처: 'supplier',
	제조사: 'maker',
	'제품 번호': 'number',
	'정척 여부': 'preferThickness',
	'유찰 횟수': 'failCount',
	'용도 코드': 'usageCode',
	용도명: 'usageCodeName',
	'여재 원인': 'causeCode',
	'여재 원인명': 'causeCodeName',
	수정일: 'updateDate',
	'클레임 진행상태': 'claimStatus',
	'입고 상태': 'receiptStatus',
	'현대제철 반품일자': 'hsReturnDate',
	'카스코 반품일자': 'kaskoReturnDate',
	등록일자: 'createDate',
	확정전송일자: 'sendDate',
	매입가: 'price',
	메모: 'memo',
	'규격 약호': 'spec',
	패키지명: 'packageName',
	'패키지 번호': 'packageNumber',
	목적지명: 'destinationName',
	'운반비 공급가': 'freightCost',
	'운반비 부가세': 'freightCostVat',
	'제품 금액': 'totalOrderProductPrice',
	운반비금액: 'totalFreightCost',
	'총 공급가(원/톤)': 'totalSupplyCost',
	'총 부가세(원/톤)': 'totalVatCost',
	'합계 금액': 'totalCost',
	'제품 공급가(원/톤)': 'orderPrice',
	'제품 부가세': 'orderPriceVat',
	'기본 운임 단가': 'freightFee',
	'할증 운임 단가': 'extraUnitPrice',
	'낙찰 총 단가(원/톤)': 'totalBiddingPrice',
	'제품 낙찰 단가(원/톤)': 'productBiddingPrice',
	'상시 판매 상태': 'saleStatus',
	상시판매가: 'salePrice',
	'낙찰 상태': 'biddingStatus',
	'최종 수정자': 'lastedUpdater',
	비고: 'note',
	'Pro. No 번호': 'productNoNumber',
	'경매 번호': 'auctionNumber',
	'주문 상태': 'orderStatus',
	'경매 등록 상태': 'registrationStatus',
	'경매 시작 단가': 'auctionStartPrice',
	'판매 구분': 'saleCategory',
	'판매 제외 사유': 'excludeSaleReason',
	'판매 유형': 'saleType',
	'판매가 유형': 'salePriceType',
	'고객사 명': 'customerName',
}
