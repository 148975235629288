// Atoms.js
import { atom } from 'jotai'

// Global Search Component Toggle Button.
export const globalProductSearchToggleAtom = atom(true)

// 규경 약호 모달
export const kyuModalAtom = atom(false)

// 프로넘 모달
export const proNoModalAtom = atom(false)

// 고객사 명 모달
export const customerModalAtom = atom(false)
