import React, { useEffect, useState } from 'react'
import {
	Alert,
	HalfWrap,
	Left,
	MainTitle,
	OnePageContainer,
	OnePageSubContainer,
	Part,
	Right,
	Title,
} from '../../../common/OnePage/OnePage.Styled'
import { CustomInput } from '../../../common/Input/Input'
import { styled } from 'styled-components'
import { RadioCircleDiv, RadioInnerCircleDiv, RadioMainDiv } from '../../../common/Check/RadioImg'
import { CheckBox } from '../../../common/Check/Checkbox'
import { get_addressFind, get_detailClientDestination, patch_clientDestination } from '../../../api/userManage'
import { BlackBtn, WhiteBtn } from '../../../common/Button/Button'
import { isEmptyObj } from '../../../lib'
import useMutationQuery from '../../../hooks/useMutationQuery'
import { UsermanageFindModal } from '../../../store/Layout/Layout'
import { useAtom } from 'jotai'
import ClientDestiCustomerFind from './ClientDestiCustomerFind'
import useReactQuery from '../../../hooks/useReactQuery'
import useAlert from '../../../store/Alert/useAlert'
import AddressFinder from '../../../components/DaumPost/Address'
import { getSpecialDestination } from '../../../api/search'
import { MainSelect } from '../../../common/Option/Main'
import { phoneRegex, faxRegex } from '../../../common/Regex/Regex'

const init = {
	uid: '',
	represent: '',
	customerUid: '',
	destinationUid: '',
	address: '',
	addressDetail: '',
	name: '',
	managerTitle: '',
	managerName: '',
	managerPhone: '',
	phone: '',
	memo: '',
}

const DestinationEdit = ({ uidAtom, setEditModal }) => {
	const { showAlert, simpleConfirm, simpleAlert } = useAlert()

	const { data } = useReactQuery(uidAtom, 'detailclientDestination', get_detailClientDestination)
	const matchingData = data?.data?.data

	const [address, setAddress] = useState('')
	const [detailAddress, setDetailAddress] = useState('')
	const [postAddress, setPostAddress] = useState('')
	const [findModal, setFindModal] = useAtom(UsermanageFindModal)
	const [customerFindResult, setCustomerFindResult] = useState()
	const [customerNameInput, setCustomerNameInput] = useState({})
	const [destiCode, setDestiCode] = useState('')

	const [specialDestinations, setSpecialDestinations] = useState([])
	const [selectedSpecialDestination, setSelectedSpecialDestination] = useState(null)

	const [phoneError, setPhoneError] = useState('')
	const [faxError, setFaxError] = useState('')

	const getSpecials = async () => {
		const response = await getSpecialDestination()
		response.forEach((item) => {
			if (matchingData.destinationName === item.value) {
				setSelectedSpecialDestination(item)
			}
		})
		setSpecialDestinations(response)
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (postAddress) {
					const response = await get_addressFind(postAddress)
					const resData = response?.data?.data
					if (resData) {
						setDestiCode(resData)
					} else {
						setDestiCode('미등록 또는 대기 중인 코드입니다.')
					}
				}
			} catch (error) {
				console.error(error)
			}
		}

		fetchData()
	}, [postAddress, get_addressFind])

	const radioDummy = ['지정', '미지정']
	const [checkRadio, setCheckRadio] = useState(Array.from({ length: radioDummy.length }, () => false))

	const [submitData, setSubmitData] = useState(init)

	const mutation = useMutationQuery('', patch_clientDestination)

	function onAddressHandler(address, addressDetail, sido, sigungu, bname) {
		const destination = `${sido} ${sigungu} ${bname}`
		setPostAddress(address)
		setDetailAddress('')
		setSubmitData((p) => ({ ...p, address, addressDetail, destination }))
	}

	useEffect(() => {
		const checkedIndex = checkRadio.findIndex((isChecked, index) => isChecked && index < radioDummy.length)
		if (checkedIndex === 0) {
			setSubmitData({ ...submitData, represent: 1 })
		}
		if (checkedIndex === 1) {
			setSubmitData({ ...submitData, represent: 0 })
		}
	}, [checkRadio])

	useEffect(() => {
		setSubmitData({ ...submitData, ...customerNameInput })
	}, [customerNameInput])

	useEffect(() => {
		const checkedIndex = matchingData?.represent === 0 ? 1 : 0
		const newCheckRadio = Array.from({ length: radioDummy.length }, (_, index) => index === checkedIndex)

		setPostAddress(matchingData?.address)
		setAddress(matchingData?.address)
		setDetailAddress(matchingData?.addressDetail)
		setCheckRadio(newCheckRadio)
		setCustomerNameInput({
			customerName: matchingData?.customerName,
			customerCode: matchingData?.customerCode,
		})
		setSubmitData({
			...submitData,
			...matchingData,
		})
		if (matchingData) {
			getSpecials()
		}
	}, [matchingData])

	const eventHandle = (e) => {
		const { name, value } = e.target
		setSubmitData({ ...submitData, [name]: value, address: address, addressDetail: detailAddress })

		if (name === 'managerPhone') {
			if (!phoneRegex.test(value)) {
				setPhoneError('올바른 휴대폰 번호 형식이 아닙니다.')
			} else {
				setPhoneError('')
			}
		}

		if (name === 'phone') {
			if (!faxRegex.test(value)) {
				setFaxError('올바른 전화번호 형식이 아닙니다.')
			} else {
				setFaxError('')
			}
		}
	}

	const submitHandle = (e) => {
		if (isEmptyObj(submitData)) {
			if (!!selectedSpecialDestination && !submitData.address.startsWith(selectedSpecialDestination.value)) {
				simpleAlert('선택한 특수목적지로 주소를 다시 검색해주세요.')
				return
			}

			if (phoneError || faxError) {
				simpleAlert('올바른 전화번호 형식을 입력해주세요.')
				return
			}

			mutation.mutate(submitData, {
				onSuccess: () => {
					showAlert({
						title: '수정되었습니다.',
						func: () => {
							window.location.reload()
						},
					})
				},
				onError: (e) => {
					if (e?.data?.status === 400) {
						simpleAlert(e.data?.message)
					}
				},
			})
		} else {
			simpleAlert('내용을 모두 기입해주세요.')
		}
	}

	useEffect(() => {
		return () => {
			setEditModal(false)
		}
	}, [])

	return (
		<OnePageContainer style={{ minHeight: '88vh' }}>
			<MainTitle>고객사 목적지 수정</MainTitle>
			<OnePageSubContainer>
				<HalfWrap>
					<Left style={{ width: '50%' }}>
						<Part>
							<Title>
								<h4>대표 목적지 지정</h4>
								<p></p>
							</Title>
							<RadioContainer>
								{radioDummy.map((text, index) => (
									<RadioMainDiv key={index}>
										<RadioCircleDiv
											isChecked={checkRadio[index]}
											onClick={() => {
												setCheckRadio(CheckBox(checkRadio, checkRadio.length, index))
											}}
										>
											<RadioInnerCircleDiv isChecked={checkRadio[index]} />
										</RadioCircleDiv>
										<div style={{ display: 'flex', marginLeft: '5px' }}>{text}</div>
									</RadioMainDiv>
								))}
							</RadioContainer>
						</Part>
						<Part>
							<Title>
								<h4>고객사 명</h4>
								<p></p>
							</Title>
							<CustomInput width={150} value={customerNameInput?.customerName} />
							<span style={{ margin: 'auto 5px' }}>-</span>
							<CustomInput width={150} value={customerNameInput?.customerCode} />
						</Part>

						<Part>
							<Title>
								<h4>특수목적지 선택</h4>
								<p></p>
							</Title>
							<MainSelect
								width={320}
								options={specialDestinations}
								defaultValue={specialDestinations[0]}
								value={selectedSpecialDestination || specialDestinations[0]}
								name="selectedSpecialDestination"
								onChange={(e) => setSelectedSpecialDestination(e)}
							/>
						</Part>

						<Part>
							<Title>
								<h4>목적지</h4>
								<p></p>
							</Title>
							<AddressFinder
								onAddressChange={onAddressHandler}
								prevAddress={selectedSpecialDestination?.value ?? address}
								prevAddressDetail={detailAddress}
								defaultQuery={selectedSpecialDestination?.value}
							/>
						</Part>

						<Part>
							<Title>
								<h4>적용 목적지</h4>
								<p></p>
							</Title>
							<div
								style={{
									display: 'flex',
									width: '345px',
								}}
							>
								<div>
									<CustomInput width={340} value={destiCode} disabled />
								</div>
							</div>
						</Part>
					</Left>
					<Right style={{ width: '50%' }}>
						<Part>
							<Title>
								<h4>하차지 명</h4>
								<p></p>
							</Title>
							<CustomInput
								placeholder="제 1창고,제 2창고 등."
								width={340}
								name="name"
								onChange={eventHandle}
								defaultValue={matchingData?.name}
							/>
						</Part>
						<Part>
							<Title>
								<h4>하차지 담당자 정보</h4>
								<p></p>
							</Title>
							<CustomInput
								placeholder="담당자 성함 입력"
								width={200}
								name="managerName"
								onChange={eventHandle}
								defaultValue={matchingData?.managerName}
							/>
							<CustomInput
								placeholder="직함 입력"
								width={135}
								style={{ marginLeft: '5px' }}
								name="managerTitle"
								onChange={eventHandle}
								defaultValue={matchingData?.managerTitle}
							/>
							<CustomInput
								type="tel"
								placeholder="담당자 휴대폰 번호 입력 ('-' 제외)"
								width={340}
								style={{ marginTop: '5px' }}
								name="managerPhone"
								onChange={eventHandle}
								defaultValue={matchingData?.managerPhone}
							/>
							{phoneError && <ErrorMessage>{phoneError}</ErrorMessage>}

							<Alert style={{ margin: '5px auto' }}>*하차지 연락처 미입력 시 토요일 하차 불가</Alert>
							<CustomInput
								type="tel"
								placeholder="하차지 연락처 입력 ('-' 제외)"
								width={340}
								name="phone"
								onChange={eventHandle}
								defaultValue={matchingData?.phone}
							/>
							{faxError && <ErrorMessage>{faxError}</ErrorMessage>}
						</Part>

						<Part>
							<Title>
								<h4>하차지 특이사항(비고)</h4>
								<p></p>
							</Title>
							<CustomInput
								placeholder="하차지 특이사항 작성"
								width={340}
								name="memo"
								onChange={eventHandle}
								defaultValue={matchingData?.memo}
							/>
						</Part>
					</Right>
				</HalfWrap>
			</OnePageSubContainer>
			<BtnWrap bottom={-250}>
				<WhiteBtn
					width={40}
					height={40}
					onClick={() =>
						simpleConfirm('현재 작업 중인 내용이 저장되지 않았습니다.\n 페이지를 나가겠습니까?', () => {
							setEditModal(false)
						})
					}
				>
					돌아가기
				</WhiteBtn>
				<BlackBtn width={40} height={40} onClick={submitHandle}>
					저장
				</BlackBtn>
			</BtnWrap>
			{findModal && (
				<ClientDestiCustomerFind
					setFindModal={setFindModal}
					setCustomerFindResult={setCustomerFindResult}
					customerNameInput={customerNameInput}
					setCustomerNameInput={setCustomerNameInput}
				/>
			)}
		</OnePageContainer>
	)
}

export default DestinationEdit

const RadioContainer = styled.div`
	display: flex;
	width: 250px;
	justify-content: space-between;
`

const BtnWrap = styled.div`
	display: flex;
	width: 400px;
	height: 50px;
	justify-content: space-evenly;
	align-items: center;
	margin: 60px auto;
`

const ErrorMessage = styled.p`
	color: red;
	font-size: 12px;
	margin-top: 5px;
`
