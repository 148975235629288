export function KilogramSum(data, name) {
	let sum = 0
	if (Array.isArray(data)) {
		data.forEach((i) => {
			const targetData = i['중량'] || i['중량 합계'] || i['총 중량'] || i['총 중량 합계']

			if (name) {
				sum += Number(i[name])
			} else if (targetData?.includes(',')) {
				sum += Number(targetData.replace(/,/g, ''))
			} else if (i[name]?.includes(',')) {
				sum += Number(i[name]?.replace(/,/g, ''))
			} else {
				sum += Number(targetData)
			}
		})
	}
	return new Intl.NumberFormat().format(sum)
}
