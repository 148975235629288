import { useAtomValue } from 'jotai'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { shipmentInvoiceAllListQuery } from '../../../api/shipment'
import { WhiteSkyBtn } from '../../../common/Button/Button'
import { BlueBarHeader, FadeOverlay, WhiteCloseBtn } from '../../../modal/Common/Common.Styled'
import { FilterContianer } from '../../../modal/External/ExternalFilter'
import useAlert from '../../../store/Alert/useAlert'
import { selectedRowsAtom } from '../../../store/Layout/Layout'

const RECEIPT_MODES = ['물품수취서', '거래명세서', '거래명세서', '인수증']

const groupData = (data, getKey) => {
	return data?.reduce((acc, item) => {
		const key = getKey(item)
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(item)
		return acc
	}, {})
}

export default function ReceiptExcelV2() {
	const { simpleAlert } = useAlert()
	const selectedRows = useAtomValue(selectedRowsAtom)
	const [open, setOpen] = useState(false)
	const [data, setData] = useState([])

	const getData = async (outUid) => {
		return await shipmentInvoiceAllListQuery(outUid)
	}

	const handleClick = async () => {
		if (!selectedRows || selectedRows?.length === 0) {
			return simpleAlert('출력할 제품을 선택해주세요.')
		}
		if (!selectedRows || selectedRows?.length > 1) {
			return simpleAlert('수취서 출력은 하나만 가능합니다.')
		}
		const selectItem = selectedRows[0]
		const outUid = selectItem['출고 고유번호']

		const rawData = await getData(outUid)
		setData(groupData(rawData, (item) => `${item.customerCode}`))
		setOpen(true)
	}

	return (
		<>
			{open && <ReceiptForm data={data} closeModal={() => setOpen(false)} />}
			<WhiteSkyBtn onClick={handleClick}>수취서 출력</WhiteSkyBtn>
		</>
	)
}

function ReceiptForm({ data, closeModal }) {
	const containerRef = useRef(null)

	const handleExtract = useReactToPrint({
		content: () => containerRef.current,
		documentTitle: `수취서_${data[Object.keys(data)[0]][0].outNumber}.pdf`,
		onAfterPrint: () => {
			window.location.reload()
		},
	})

	useEffect(() => {
		handleExtract()
	}, [])

	return (
		<>
			<FadeOverlay />
			<NewModalContainer>
				<BlueBarHeader style={{ height: '20px' }}>
					<div></div>
					<div>
						<WhiteCloseBtn onClick={closeModal} src="/svg/white_btn_close.svg" />
					</div>
				</BlueBarHeader>
				<Container ref={containerRef}>
					{data &&
						Object.keys(data).map((key) => {
							const list = data[key]
							const totalWeight = list
								.map((item) => item.weight)
								.reduce((acc, cur) => Number(acc) + Number(cur), 0)
								.toLocaleString()
							return RECEIPT_MODES.map((title, index) => (
								<BlueSubContainer2 key={index}>
									<ContentWrapper>
										<FilterContianer>
											<FormTitle>
												<b>{title}</b>
											</FormTitle>
										</FilterContianer>
										<MyTable>
											<tr>
												<th>창고명</th>
												<td>{list[0].storageName}</td>
											</tr>
											<tr>
												<th>일련번호</th>
												<td>{list[0].outNumber}</td>
												<td colSpan={4} style={{ border: 0, background: '#fff' }}></td>
												{title === '거래명세서' && index === 1 && <td>정문회수용</td>}
												{title === '거래명세서' && index === 2 && <td>공급받는자용</td>}
												{title === '인수증' && <td>회수용</td>}
											</tr>
											<tr>
												<th>출고일자</th>
												<th>거래처</th>
												<th>인도조건</th>
												<th>차량번호</th>
												<th>도착지</th>
												<th>도착지 전화번호</th>
												<th>담당자연락처</th>
											</tr>
											<tr>
												<td>{moment(list[0].outDate).format('YYYY-MM-DD') || ''}</td>
												<td>{list[0].customerName || ''}</td>
												<td>도착도</td>
												<td>{list[0].carNumber}</td>
												<td>{list[0].destinationName}</td>
												<td>{list[0].customerDestinationPhone}</td>
												<td>{list[0].customerDestinationManagerPhone}</td>
											</tr>
											<tr>
												<th>도착지상세주소</th>
												<td colSpan={6}>
													{list[0].customerDestinationAddress || ''}
													{'   '}
													{list[0].customerDestinationAddressDetails || ''}
												</td>
											</tr>
											<tr>
												<th>상차중량(Kg)</th>
												<td>{totalWeight}</td>
												<th>총수량</th>
												<td>{list?.length || 0}</td>
												<th>도착지명</th>
												<td colSpan={2}>{list[0].customerDestinationName || ''}</td>
											</tr>
										</MyTable>
										<MyTable style={{ marginTop: '14px' }}>
											<tr>
												<th>NO.</th>
												<th>주문번호</th>
												<th>품명</th>
												<th>제품번호</th>
												<th>규격약호</th>
												<th>등급</th>
												<th>두께</th>
												<th>폭</th>
												<th>길이</th>
												<th>중량</th>
											</tr>
											{list?.map((item, idx) => (
												<tr key={idx}>
													<td>{idx + 1}</td>
													<td>{item.orderNumber}</td>
													<td>{item.spart}</td>
													<td>{item.productNumber}</td>
													<td>{item.spec}</td>
													<td>{item.grade}</td>
													<td>{Number(item.thickness).toLocaleString()}</td>
													<td>{Number(item.width).toLocaleString()}</td>
													<td>{Number(item.length).toLocaleString()}</td>
													<td>{Number(item.weight).toLocaleString()}</td>
												</tr>
											))}
											<tr>
												<th colSpan={2}>합계</th>
												<td colSpan={7}></td>
												<td>{totalWeight}</td>
											</tr>
										</MyTable>
									</ContentWrapper>

									<BottomSection>
										<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
											<DepositRequestBottom>
												<div>
													<img src="/img/logo.png" width={48} height={40} alt="Logo" />
												</div>
												<PageText>
													{index + 1} / {RECEIPT_MODES.length}
												</PageText>
											</DepositRequestBottom>
											<div
												style={{
													display: 'flex',
													width: '550px',
													border: '1px solid #c8c8c8',
													borderCollapse: 'collapse',
												}}
											>
												<div
													style={{
														width: '25%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														background: '#dbe2f0',
														borderCollapse: 'collapse',
													}}
												>
													인수 확인
												</div>
												<div
													style={{
														width: '75%',
														display: 'flex',
														flexDirection: 'column',
														height: '100%',
														justifyContent: 'center',
														border: '1px solid #c8c8c8',
														borderRight: 'none',
														borderCollapse: 'collapse',
													}}
												>
													<div
														style={{
															borderRight: 'none',
															borderTop: 'none',
															display: 'flex',
															width: '100%',
															flexDirection: 'row',
															backgroundColor: 'dbe2f0',
															height: '50%',
															justifyContent: 'center',
															alignItems: 'center',
															border: '1px solid #c8c8c8',
														}}
													>
														<p style={{ flex: '2 1 0%' }}></p>
														<p style={{ flex: '2 1 0%' }}>월</p>
														<p style={{ flex: '2 1 0%' }}>일</p>
														<p style={{ flex: '1 1 0%' }}>인</p>
													</div>
													<div
														style={{
															borderRight: 'none',
															borderBottom: 'none',
															display: 'flex',
															width: '100%',
															justifyContent: 'center',
															alignItems: 'center',
															background: '#fff',
															height: '50%',
															border: '1px solid #c8c8c8',
														}}
													>
														도착 제품이 이상없음을 확인합니다.
													</div>
												</div>
											</div>
										</div>
									</BottomSection>
								</BlueSubContainer2>
							))
						})}
				</Container>
			</NewModalContainer>
		</>
	)
}

const Container = styled.div`
	width: 210mm;
	height: 297mm;
	display: flex;
	flex-direction: column;
	margin: 0;
	overflow: auto;

	@media print {
		width: 100%;
		height: auto;
		overflow: visible;
	}
`

const FormTitle = styled.h1`
	font-size: 30px;
	padding: 20px;
	text-align: center;
`

const MyTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;
	table-layout: fixed;

	& tr {
		min-width: max-content;
		border: 1px solid #c8c8c8;
		padding: 10px;
	}

	& th {
		min-width: max-content;
		padding: 6px;
		font-weight: 500;
		border: 1px solid #c8c8c8;
		background-color: #dbe2f0;
		white-space: nowrap;
	}

	& td {
		padding: 6px;
		font-weight: 500;
		border: 1px solid #c8c8c8;
		background-color: #fff;
		text-align: center;
		word-wrap: break-word;
	}
`

const DepositRequestBottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
	z-index: 9999;
	width: 100%;
	height: 70px;
	padding: 15px 30px;
`

const PageText = styled.div`
	width: 33%;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
`

const BlueSubContainer2 = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
	width: 210mm;
	min-height: 297mm; // 최소 높이를 297mm로 설정
	page-break-after: always;
	box-sizing: border-box;
	justify-content: space-between;
	overflow: hidden;

	@media print {
		width: 100%;
		height: 100vh; // 세로 모드에서 뷰포트 높이 전체를 차지
		page-break-after: always;
		overflow: hidden;
	}

	@media print and (orientation: landscape) {
		height: auto; // 가로 모드에서는 높이를 자동으로 조정
		min-height: 210mm; // 가로 모드의 최소 높이
	}
`

const ContentWrapper = styled.div`
	flex-grow: 1;
	overflow: hidden;
`

const BottomSection = styled.div`
	margin-top: auto;
`

const NewModalContainer = styled.div`
	opacity: 0;
	width: 100%;
	max-width: 210mm;
	height: 100%;
	max-height: 297mm;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #eef3fb;
	z-index: ${(props) => props.zindex || 9999};
	overflow: auto;

	@media print {
		position: static;
		transform: none;
		overflow: visible;
		max-height: none;
	}
`
