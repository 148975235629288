// @import url('https://webfontworld.github.io/sunn/SUIT.css');

const KaskoTheme = {
  colors: {
    //Primary
    PriNormal: '#4C83D6',
    PriStrong: '#17479e',
    PriHeavy: '#061737',
    PriAlter: '#A0BBE3',
    PriAssist: '#CDDDF4',
    PriDisabled: '#ABBFDE',

    //Text
    TxtStrong: '#090909',
    TxtNormal: '#202020',
    TxtAlter: '#6B6B6B',
    TxtAssist: '#ACACAC',
    TxtDisabled: '#D7D7D7',

    //Status
    StatAlert: '#B02525',
    StatCaut: '#EDAF35',
    StatPosit: '#64B5FF',
    StatPosub: '#008859',

    //Line
    LineNormal: '#E1E1E1',
    LineHeavy: '#C8C8C8',
    LineAlert: '#EF8F8F',
    LinePosit: '#4CA9FF',
    LinePosub: '#0BD58F',

    //Table BackGround
    TbgNormal: '#DBE2F0',
    TbgStrong: '#C9D3E8',
    TbgActive: '#F2FCE8',

    //Fill
    FilNormal: '#F1F1F1',
    FilStrong: '#E1E1E1',
    FilActive: '#F0F1F6',
    FilDisabled: '#ACACAC',

    //BackGround
    BgPrimary: '#EEF3FB',
    BgSecond: '#FFFFFF',
    BgWhite: '#FFFFFF',
    BgBlack: '#000000',

    //Gradient (참고)
    // normal : #17479E ->#4C83D6
    // Strong : #2B3445 ->#3F4C64
    // Alter : #A0BBE3 ->#CDDDF4
  },
};

export default KaskoTheme;
