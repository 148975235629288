import { useMemo } from 'react'
import { getSPartList, getSpecList, getStorageList } from '../api/search'
import useReactQuery from './useReactQuery'

/**
 * @description
 * 검색 목록 SelectList에 사용되는 옵션 값들
 * @returns
 */
const useGlobalProductSearchFieldData = () => {
	// 창고 목록
	const { data: storage } = useReactQuery('', 'getStorageList', getStorageList)
	const storageList = useMemo(
		() =>
			storage
				? [{ label: '창고', value: '', address: null }, ...storage.map((item) => ({ ...item, value: item.label }))]
				: [{ label: '창고', value: '', address: null }],
		[storage],
	)

	// 매입처 목록
	const supplierList = [
		{ label: '전체', value: '' },
		{ label: '현대제철', value: '현대제철' },
		{ label: '동은 스틸', value: '동은스틸' },
	]

	// 제품군 목록
	const { data: spart } = useReactQuery('', 'getSPartList', getSPartList)
	const spartList = useMemo(
		() =>
			spart
				? [{ label: '제품군', value: '' }, ...spart.map((item) => ({ ...item, value: item.label }))]
				: [{ label: '제품군', value: '' }],
		[spart],
	)

	// 제조사 목록
	const makerList = [
		{
			label: '제조사',
			value: '',
		},
		{
			label: '현대제철',
			value: '현대제철',
		},
		{
			label: '동은스틸',
			value: '동은스틸',
		},
	]
	// 재고 상태 목록
	const stockStatusList = [
		{
			label: '재고 상태',
			value: '',
		},
		{
			label: '타사 재고',
			value: '타사 재고',
		},
		{
			label: '자사 재고',
			value: '자사 재고',
		},
	]
	// 등급 목록
	const gradeList = [
		{
			label: '등급 목록',
			value: '',
		},
		{
			label: '1등급',
			value: 1,
		},
		{
			label: '2등급',
			value: 2,
		},
	]
	// 정척 여부
	const preferThicknessList = [
		{
			label: '정척여부',
			value: '',
		},
		{
			label: 'Y',
			value: 'Y',
		},
		{
			label: 'N',
			value: 'N',
		},
	]

	// 고객사 유형
	const customerList = [
		{
			label: '고객사 유형',
			value: '',
		},
		{
			label: 'Y',
			value: 'Y',
		},
		{
			label: 'N',
			value: 'N',
		},
	]

	// 규격약호 목록
	const { data: specList } = useReactQuery({}, 'getSpecList', getSpecList)

	// 경매 등록 상태
	const registrationStatusList = [
		{
			checked: false,
			text: '경매 등록',
			value: '경매 등록',
		},
		{
			checked: false,
			text: '경매 등록 대기',
			value: '경매 등록 대기',
		},
	]

	return {
		storageList,
		supplierList,
		spartList,
		makerList,
		stockStatusList,
		gradeList,
		preferThicknessList,
		specList,
		registrationStatusList,
		// inventoryDestination,
		// inventoryCustomer,
	}
}

export default useGlobalProductSearchFieldData
