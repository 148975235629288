import React, { useEffect, useState } from 'react'
import {
	BlueBarHeader,
	BlueBlackBtn,
	BlueBtnWrap,
	BlueMainDiv,
	BlueSubContainer,
	BlueSubDiv,
	FadeOverlay,
	ModalContainer,
	WhiteCloseBtn,
} from '../../../modal/Common/Common.Styled'
import { InputContainer, NoOutInput, Unit } from '../../../common/Input/Input'
import { surEditModalAtom } from '../../../store/Layout/Layout'
import { useAtomValue } from 'jotai'

const initValue = {
	lengthMax: '',
	lengthMin: '',
	percent: '',
	uid: '',
	widthMax: '',
	widthMin: '',
}

const SurchargeProcessModal = ({
	setBtnCellModal,
	title,
	propsHandler,
	initdata = {
		lengthMax: '',
		lengthMin: '',
		percent: '',
		uid: '',
		widthMax: '',
		widthMin: '',
	},
}) => {
	const [form, setForm] = useState(initdata)
	const modalMode = useAtomValue(surEditModalAtom)

	const onChangeHandler = (e) => {
		const { name, value } = e.target
		const newValue = name === 'percent' ? parseInt(value, 10) : value
		setForm((prevValues) => ({
			...prevValues,
			[name]: newValue,
		}))
	}

	const modalClose = () => {
		setBtnCellModal(false)
	}

	const onSubmit = () => {
		propsHandler(form)
	}

	useEffect(() => {
		if (modalMode === '등록') {
			setForm(initValue)
		}
	}, [modalMode])

	return (
		// 기준 관리 -
		<>
			<FadeOverlay />
			<ModalContainer width={650}>
				<BlueBarHeader>
					<div>{title}</div>
					<div>
						<WhiteCloseBtn onClick={modalClose} src="/svg/white_btn_close.svg" />
					</div>
				</BlueBarHeader>
				<BlueSubContainer>
					<div>
						<BlueMainDiv>
							<BlueSubDiv style={{ height: '80px' }}>
								<h6>길이 입력</h6>
								<InputContainer style={{ width: '190px' }}>
									<NoOutInput
										name="lengthMin"
										value={form.lengthMin}
										onChange={onChangeHandler}
										style={{ fontSize: '16px' }}
										placeholder="최소 길이"
										type="number"
									/>
									<Unit>mm</Unit>
								</InputContainer>
								<p style={{ margin: '0px 5px' }}>~</p>
								<InputContainer>
									<NoOutInput
										name="lengthMax"
										onChange={onChangeHandler}
										value={form.lengthMax}
										style={{ fontSize: '16px' }}
										placeholder="최대 길이"
										type="number"
									/>
									<Unit>mm</Unit>
								</InputContainer>
							</BlueSubDiv>
							<BlueSubDiv style={{ height: '80px' }} bor>
								<h6>폭 입력</h6>
								<InputContainer style={{ width: '190px' }}>
									<NoOutInput
										name="widthMin"
										onChange={onChangeHandler}
										value={form.widthMin}
										style={{ fontSize: '16px' }}
										placeholder="최소 폭"
										type="number"
									/>
									<Unit>mm</Unit>
								</InputContainer>
								<p style={{ margin: '0px 5px' }}>~</p>
								<InputContainer>
									<NoOutInput
										name="widthMax"
										onChange={onChangeHandler}
										value={form.widthMax}
										style={{ fontSize: '16px' }}
										placeholder="최대 폭"
										type="number"
									/>
									<Unit>mm</Unit>
								</InputContainer>
							</BlueSubDiv>
							<BlueSubDiv style={{ height: '80px' }} bor>
								<h6>할증</h6>
								<InputContainer style={{ width: '415px' }}>
									<NoOutInput
										name="percent"
										onChange={onChangeHandler}
										value={form.percent}
										style={{ fontSize: '16px' }}
										placeholder="퍼센트 값"
										type="number"
									/>
									<Unit>%</Unit>
								</InputContainer>
							</BlueSubDiv>
						</BlueMainDiv>
					</div>
					<BlueBtnWrap>
						<BlueBlackBtn onClick={onSubmit}>저장</BlueBlackBtn>
					</BlueBtnWrap>
				</BlueSubContainer>
			</ModalContainer>
		</>
	)
}

export default React.memo(SurchargeProcessModal)
